<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">
        Write balanced chemical equations for each of the acid-base reactions described below.
      </p>

      <p>
        <stemble-latex
          content="$\text{a) Aqueous solutions of HClO}_4\text{ and LiOH are mixed}$"
        />
      </p>
      <p>
        <chemical-notation-input
          v-model="inputs.reactionA"
          dense
          :show-note="false"
          class="mb-8"
          :disabled="!allowEditing"
        />
      </p>

      <p>
        <stemble-latex
          content="$\text{b) Aqueous NaOH is mixed with an aqueous solution of HNO}_3$"
        />
      </p>
      <chemical-notation-input
        v-model="inputs.reactionB"
        dense
        :show-note="false"
        class="mb-8"
        :disabled="!allowEditing"
      />

      <p>
        <stemble-latex
          content="$\text{c) Aqueous Ba(OH)}_2\text{ is reacted with two equivalents of gaseous HCl}$"
        />
      </p>
      <chemical-notation-input
        v-model="inputs.reactionC"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question229',
  components: {
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        reactionA: null,
        reactionB: null,
        reactionC: null,
      },
    };
  },
};
</script>
